@import '@simpl/element-theme/src/theme';

// Import Element Components
@import '@simpl/element-ng/simpl-element-ng';
@import '@siemens/ngx-datatable/themes/bootstrap';
@import "ngx-toastr/toastr";
// @import 'ol/ol.css';
// @import 'ol-ext/dist/ol-ext.css';
@import '~maplibre-gl/dist/maplibre-gl.css';

router-outlet {
	display: none;
}

html {
	font-size: 87.5%;
	// scrollbar-color: color-mix(in srgb, var(--md-accent-fg-color) 60%, var(--element-base-1)) var(--element-base-1);
	// scrollbar-color: var(--md-accent-fg-color) #0000;
	scrollbar-gutter: auto;
	scrollbar-width: thin;
}

// @media (max-width: 37.5em) {
//     html {
//         font-size: 87.5%;
//     }
// }

// @media (max-width: 56.25em) {
//     html {
//         font-size: 50%;
//     }
// }

// @media (max-width: 75em) {
//     html {
//         font-size: 56.25%;
//     }
// }

// @media (min-width:75.1em) and (max-width: 112.5em) {
//     html {
//         // font-size: 77%;
//     }
// }

// @media (min-width: 112.5em) {
//     html {
//         font-size: 100%;
//     }
// }

// @media (min-width: 125em) {
//     html {
//         font-size: 112.5%;
//     }
// }

.ngx-datatable.table-element .datatable-body .datatable-body-row:hover:not(.active) .datatable-row-group {
	background-color: initial;
}

.bs-datepicker {
	box-shadow: 0 0 8px var(--element-box-shadow-color-1), 0 8px 8px var(--element-box-shadow-color-2);
	background: var(--element-base-1);
	border-radius: var(--element-radius-2);
}

.bs-datepicker-head {
	background-color: transparent !important;
	border-block-end: 1px solid var(--element-ui-4);
}

.bs-datepicker-head button {
	color: var(--element-text-active);
}

.bs-datepicker-head button.current {
	font-weight: 700;
	font-size: .875rem;
	line-height: 1.143;
}

.bs-datepicker-body {
	border: none;
}

.bs-datepicker-body table td {
	color: var(--bs-body-color);
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
	background: var(--element-base-1-hover);
	color: var(--element-ui-0);
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after,
.theme-green .bs-datepicker-body table td[class*=select-] span:after {
	background: var(--element-ui-0);
	color: var(--element-base-1);
}

.bs-datepicker-head button[disabled],
.bs-datepicker-head button[disabled]:hover,
.bs-datepicker-head button[disabled]:active {
	background: none;
	color: var(--element-text-active);
	pointer-events: none;
	cursor: default;
	opacity: var(--element-action-disabled-opacity);
}

.benchmark-table-progressbar {
  & .echart-container {
    height: 64px !important;
    & > div {
      height: 64px !important;

      & canvas {
        height: 64px !important;
      }
    }
  }
}

// ::ng-deep {
//     // center align datatable cell
//     .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .text-align-center-cell  {
//         display: flex;
//         text-align: center;
// 		justify-content: center;
//     }
// }

.form-control-has-icon .form-control {
	padding-inline-end: calc(var(--input-addon-inset-end, 0px) + 24px);
}

// input:not([type=radio], [id=form-class-labelfilter-input]) {
// 	width: 24rem !important;
// }

// .metric-input,
// input:not([type=radio], [id=form-class-labelfilter-input]) {
// 	width: 24rem !important;
// }




// .conf-input-calc, input:not([type=radio], [id=form-class-labelfilter-input]) {
// 	width: 10rem !important;
// }

si-select {
	width: 24rem !important;
}

si-select:not(:is(.form-control, .form-select).ng-touched.ng-invalid:not(.no-validation)) {
	padding-inline-end: var(--input-addon-inset-end, 0px);
}

// si-search-bar {
// 	input:not([type=radio]) {
// 		// width: 12rem !important;
// 		width: 12rem;
// 	}
// }

si-search-bar.tree-search {
	input {
		width: 100% !important;
	}
}



// si-search-bar.calclation-configuration {
// 	input {
// 		width: 100% !important;
// 		height: 2.1rem !important;
// 		border-radius: 0.25rem;
// 		background: var(--Base-base-1, #23233C) !important;
// 	}
// }

// .icon-filter>span{





.error-handler-app-failure {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	z-index: 3;
	position: relative;
	backdrop-filter: blur(5px);
}

// .cdk-overlay-container {
//     z-index: 1000000;
// }

.modal {
	backdrop-filter: blur(5px);
}

.media_type .ngx-datatable.table-element .datatable-body .datatable-body-row:hover:not(.active) .datatable-row-group.datatable-row-right {
	background-color: var(--element-base-1-hover) !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.separator {
	font-size: 1.5rem;
	color: var(--element-ui-2);
	margin-block: -.25rem;
	margin-inline: 0;
}


@media (max-width: 1200px) {

	html {
		font-size: 80% !important;
	}

}

@media (min-width: 992px) {
	[_nghost-ng-c3108978600]:not(.nav-collapsed) {
		padding-inline-start: 48px !important;
	}
}

.mapboxgl-ctrl-logo {
	display: none !important;
}

.mapboxgl-ctrl-group {
	background-color: #23233C;

	button {
		border: none !important;
		/* Remove all borders around the buttons */
	}
}

.mapboxgl-popup-content {
	box-shadow: none !important
}

.mapboxgl-ctrl-icon {
	filter: invert(1);
}

.popup_chart_card_container {
	background-color: transparent !important;
	box-shadow: none !important;
}

.ol-mapbox-style-background {
	// background-color: #0c0c36 !important;
}

.ol-scale-line {
	left: unset !important;
	right: 16px !important;
	background: transparent !important;
}

.ol-scale-line-inner {
	color: #B3B3BE !important;
}

.ol-zoom {
	left: unset !important;
	right: 1rem !important;
	top: .8rem !important;
	border-radius: 0 !important;
}

.ol-zoom-in {
	font-size: 1.4rem !important;
	color: white !important;
	background-color: #23233C !important;
	border-radius: 0 !important;
}

.ol-zoom-out {
	font-size: 1.4rem !important;
	color: white !important;
	background-color: #23233C !important;
	border-radius: 0 !important;
}

.ol-control button {
	&:hover {
		outline: none !important;
	}
}


body::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
body::-webkit-scrollbar-track {
  // background: #f1f1f1;
  background: transparent !important;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #66667E !important;
  border-radius: 3px !important;
  width: 6px !important;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.input-width{
	width: 24rem;
}

//benchmark table
.ngx-datatable.custom-paginator .datatable-footer .datatable-pager .pager, .ngx-datatable.custom-paginator .datatable-footer .datatable-pager .pager li{
	padding-right: 0.5rem !important;
}


.sub-item-popover {
	width: 24rem !important;
    max-inline-size: none !important;
    margin-block-start: unset !important;
}

.sub-item-popover .popover-arrow{
	display: none;
}


